<template>
  <b-overlay :show="loading" rounded="sm">
    <section class="invoice-preview-wrapper">
      <b-alert variant="danger" :show="invoiceData === undefined">
        <h4 class="alert-heading">
          Error fetchinga invoice data
        </h4>
        <div class="alert-body">
          No invoice found with this invoice id. Check
          <b-link class="alert-link" :to="{ name: 'apps-invoice-list' }">
            Invoice List
          </b-link>
          for other invoices.
        </div>
      </b-alert>
      <div class="pricing-free-trial startTrainingHeaderStyle">
        <b-row>
          <b-col xl="12">
            <div class="pricing-trial-content d-flex justify-content-between">
              <div class="text-center text-md-left mt-2" style="margin-left: 10px !important;">
                <b-row>
                  <b-col xl="12" cols="12">
                    <b-row>
                      <b-col xl="2" class="mt-2">
                        <b-img fluid :src="require('@/assets/images/logo/logo2.png')" class="pricing-trial-img"
                          alt="svg img" style="height: 120px; width: 120px" @click="takeScreenshot()" />
                      </b-col>
                      <b-col xl="7" class="mt-0">
                        <div class="text-primary" style="margin-top: 0px !important;">
                          CONTROL DE PAGOS OK <b>LAVARCLICK !</b> <span class="text-secondary">Administra Todos los Pagos
                            a
                            Afiliados LavarClick</span>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
              <div
                style="height: 10% !important; width: 30% !important; margin-top: 10px !important; margin-right: -70px !important;">

                <b-img fluid :src="require('@/assets/images/illustration/33.png')" class="pricing-trial-img" alt="svg img"
                  style="height: 200px" v-b-modal.modal-primary />
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-row v-if="invoiceData" class="invoice-preview" style="margin-top: -60px !important;">
        <b-col cols="12" md="4" xl="4" class="invoice-actions">
          <b-card style="margin-top: 0%; ">
            <b-row>
              <b-col xl="12">
                <table style="width: 100%;">
                  <tr>
                    <th style="width: 30%;">
                      Ciclo Pagos
                    </th>
                    <th style="width: 20%;">
                      Servicios
                    </th>
                    <th style="width: 20%;">
                      Comisiones
                    </th>
                    <th style="width: 20%;">
                      Total
                    </th>
                    <th style="width: 10%;">
                      Estado
                    </th>
                  </tr>
                  <tr v-for="(cycle, indexC) in computedCycleReport" v-bind:key="indexC"
                    style="border-style: solid; border-width: 0.1em;">
                    <td style="color:#000000; font-weight: bold;" v-if="cycle.status > 0 || cycle.status < 0">
                      {{ cycle.partner_name }}
                    </td>
                    <td v-if="cycle.status > 0 || cycle.status < 0">
                      $ {{ cycle.total_payorder }}
                    </td>
                    <td v-if="cycle.status > 0 || cycle.status < 0">
                      $ {{ cycle.total_invoice }}
                    </td>
                    <td v-if="cycle.status > 0 || cycle.status < 0">
                      <div v-if="cycle.status > 0">
                        $ {{ cycle.status }}
                      </div>
                      <div v-else>
                        $ {{ (cycle.status * -1) }}
                      </div>

                    </td>
                    <td v-if="cycle.status > 0 || cycle.status < 0">
                      <div v-if="cycle.status > 0">
                        PAGO
                      </div>
                      <div v-else-if="cycle.status < 0">
                        FACTURA
                      </div>
                    </td>
                  </tr>

                  <tr style="border-style: solid; border-width: 0.1em; background-color: black; color:white;">
                    <td>
                      Total Pagos
                    </td>
                    <td>
                      $ {{ total_payorders }}
                    </td>
                    <td>
                      $ {{ total_invoices }}
                    </td>
                    <td>
                      Transacciones
                    </td>
                    <td style="text-align: right;">
                      {{ computedCycleReport.length }}
                    </td>
                  </tr>
                </table>

              </b-col>
              <b-col xl="1">

              </b-col>
              <b-col xl="10">
                <table>
                  <tr>
                    <td>
                      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" 
                        variant="primary" @click="runCycle">
                        Liquidar Ciclo
                      </b-button>

                    </td>
                    <td>
                      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" >
                        <vue-excel-xlsx :data="testReportExport" :columns="columnsTestReport" :file-name="'filename'"
                          :file-type="'xlsx'" style="border-style: none !important; background-color: #7367F0; font-weight: bold; color: white; "
                          :sheet-name="'sheetname'">
                          Reporte Prueba
                        </vue-excel-xlsx>
                      </b-button>

                    </td>
                    <!-- <td>
                      <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" class="mb-75"
                        block>

                        <vue-excel-xlsx :data="computedCycleReport" :columns="columns" :file-name="'filename'"
                          :file-type="'xlsx'" style="border-style: none !important; background-color: #FFFFFF;"
                          :sheet-name="'sheetname'">
                          Descargar Dispersion
                        </vue-excel-xlsx>
                      </b-button>

                    </td>
                    <td>
                      <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" class="mb-75" block
                        @click="printInvoice">
                        Descargar Facturas
                      </b-button>

                    </td> -->
                    <td>
                      <b-button v-b-toggle.sidebar-send-invoice v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="success">
                        Iniciar Ciclo
                      </b-button>
                    </td>
                  </tr>

                  <!-- Button: DOwnload -->
                </table>


              </b-col>
            </b-row>
            <!-- Button: Send Invoice -->
            <!-- Button: Print -->

            <!-- Button: Edit -->
            <!-- <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" class="mb-75" block
            :to="{ name: 'apps-invoice-edit', params: { id: $route.params.id } }">
            Edit
          </b-button> -->

            <!-- Button: Add Payment -->


          </b-card>
          <b-card style="height: 60%;">
            <div class="d-flex align-items-center">
              Consultar x Fecha :
              <feather-icon icon="CalendarIcon" size="16" />
              <flat-pickr v-model="rangePicker" :config="{ mode: 'range' }"
                class="form-control flat-picker bg-transparent border-0 shadow-none" placeholder="YYYY-MM-DD"
                style="width: 55% !important;" />
            </div>
            <table style="border-bottom-style: solid; width: 100%;">
              <tr>
                <th colspan="5" style="border-bottom-style: solid; text-align: center; font-size: 1.4em;">
                  Historial de Ciclos
                </th>
              </tr>
              <tr>
                <th style="width: 5%;">
                  ID
                </th>
                <th style="width: 20%;">
                  Fecha
                </th>
                <th style="width: 15%;">
                  Pago
                </th>
                <th style="width: 15%;">
                  Facturas
                </th>
                <th>
                  Acciones
                </th>
              </tr>
              <tr v-for="(cycles, indexCycle) in computedCycleReportList" v-bind:key="indexCycle">
                <td style="cursor: pointer;" @click="getCycleDispersion(cycles, indexCycle)">
                  <span>{{ cycles.id_ecommerce_cycles }}</span>
                </td>
                <td>
                  {{ momentInvoice(new Date(cycles.created_at*1)).format('YYYY-MM-DD') }}
                </td>
                <td>
                  {{ total_pay }}
                </td>
                <td>
                  {{ total_inv }}
                </td>
                <td>
                  <table v-if="cycles.process_at === null">
                    <tr>
                      <td>
                        Aprobar 
                      </td>
                      <td>
                        <feather-icon
              
              icon="EyeIcon"
              size="20"
              class="mx-1"
              @click="updateCycleStatus(cycles, 0)" style="cursor: pointer;"
            />
                      </td>
                      <td>
                        Terminar 
                        
                      </td>
                      <td>
                        <feather-icon
              
              icon="SendIcon"
              size="20"
              class="mx-1"
              @click="updateCycleStatus(cycles, 1)" style="cursor: pointer;"
            />
                      </td>
                    </tr>
                  </table>
                  <table v-else>
                    <tr>
                      <td>
                        Procesado
                        </td>
                        <td>
                          {{ 
                            momentInvoice(new Date(cycles.process_at*1)).format('YYYY-MM-DD')
                             }}
                        </td>
                        </tr>
                        </table>
                  <!-- <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="updateCycleStatus(cycles, 1)">
                    Procesado
                  </b-button> -->
                </td>
              </tr>
              
            </table>
            <table style="margin-left: 15%;">
              <tr>
                <td>
                  <!-- Button: Send Invoice -->
 <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" >
                        <vue-excel-xlsx :data="testReportExport" :columns="columnsTestReport" :file-name="'filename'"
                          :file-type="'xlsx'" style="border-style: none !important; background-color: #7367F0; font-weight: bold; color: white; "
                          :sheet-name="'sheetname'">
                          Prueba
                        </vue-excel-xlsx>
                      </b-button>
                      </td>
                      <td>
                  
                  
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="primary">
                    <vue-excel-xlsx :data="invoicesReport" :columns="invoicesColumnsReport" :file-name="'filename'" :file-type="'xlsx'"
                      style="border-style: none !important; font-weight: bold; color: white; background-color: #7367F0;" :sheet-name="'sheetname'">
                      Facturas
                    </vue-excel-xlsx>
                  </b-button>

                </td>
                <td>
                  <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="primary">
                    <vue-excel-xlsx :data="dispersionReport" :columns="dispersionColumnsReport" :file-name="'filename'" :file-type="'xlsx'"
                      style="border-style: none !important; font-weight: bold; color: white; background-color: #7367F0;" :sheet-name="'sheetname'">
                      Dispersion
                    </vue-excel-xlsx>
                  </b-button>

                </td>
                <!-- <td>
                  <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="printInvoice">
                    Transaccion
                  </b-button>

                </td> -->
              </tr>
            </table>
            <!-- Button: DOwnload -->

            <!-- Button: Print -->

            <!-- Button: Edit -->
            <!-- <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" class="mb-75" block
            :to="{ name: 'apps-invoice-edit', params: { id: $route.params.id } }">
            Edit
          </b-button> -->

          </b-card>
        </b-col>
        <b-col cols="12" xl="4" md="0" v-if="flagReport">
          <b-card style="height: 80%;">
          <b-form-group class="blog-search" style="width: 80%;">

            <b-input-group class="input-group-merge">
              <b-form-input id="search-input" v-model="search_query" placeholder="Filtrar Dashboard x Servicio" />
              <b-input-group-append class="cursor-pointer" is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <div v-for="(provider, indexInv) in report" v-bind:key="indexInv">

            <table class="orderTableStyle" v-for="(bill, indexBill) in provider.bills" v-bind:key="indexBill">
              <tr v-if="indexBill === 0">
                <th colspan="4" @click="describeInvoice(provider)"
                  style="text-align: left !important; padding-left: 2% !important; border-top-left-radius: 25px; border-top-right-radius: 25px; cursor: pointer;">
                  {{ provider.name }} 350 4888077 Bogota
                </th>
                <th>
                  PAGO
                </th>
              </tr>
              <tr v-if="indexBill === 0">
                <th>
                  Orden
                </th>
                <th>
                  Servicio
                </th>
                <th>
                  Fecha
                </th>
                <th>
                  CANT
                </th>
                <th>
                  Total
                </th>
              </tr>
              <tr v-for="(payOrder, indexPO) in bill.bill.accounting.payOrders" v-bind:key="indexPO">
                <td style="width: 10% !important; text-align: center !important;"
                  v-if="payOrder.id_provider === provider.id_provider">{{ bill.fk_ecommerce_order_ec_billing }}
                </td>
                <td style="width: 50% !important;" v-if="payOrder.id_provider === provider.id_provider">
                {{ computedBillingList[indexInv][indexBill].name.trim() }}
              </td>
                <td style="width: 15% !important;" v-if="payOrder.id_provider === provider.id_provider"></td>
                <td style="width: 10% !important; text-align: center !important;"
                  v-if="payOrder.id_provider === provider.id_provider">{{ payOrder.service_items_qty }}</td>
                <td style="width: 25% !important; text-align: right !important;"
                  v-if="payOrder.id_provider === provider.id_provider">$ {{ payOrder.amount }}</td>
              </tr>
              <tr v-for="(invoice, indexIn) in bill.bill.accounting.invoices" v-bind:key="indexIn">
                <td style="width: 10% !important; text-align: center !important;"
                  v-if="invoice.id_provider === provider.id_provider">{{ invoice.id_order }}</td>
                <td style="width: 50% !important;" v-if="invoice.id_provider === provider.id_provider">Comision Servicio
                  ORDEN {{ invoice.id_order }}</td>
                <td style="width: 15% !important;" v-if="invoice.id_provider === provider.id_provider"></td>
                <td style="width: 10% !important; text-align: center !important;"
                  v-if="invoice.id_provider === provider.id_provider">1</td>
                <td style="width: 25% !important; text-align: right !important;"
                  v-if="invoice.id_provider === provider.id_provider">$ {{ invoice.ecommerce_amount }}
                </td>
              </tr>

            </table>
            <!-- <table class="orderTableStyle">
            <tr v-if="provider.bills.length === 0">
              <th colspan="4" @click="describeInvoice(provider)"
                style="text-align: left !important; padding-left: 2% !important; border-top-left-radius: 25px; border-top-right-radius: 25px; cursor: pointer;">
                {{ provider.name }} 350 4888077
              </th>
              <th>
                FACTURA
              </th>
            </tr>
            <tr v-if="provider.bills.length === 0">

              <td colspan="5">

              </td>
            </tr>
            <tr v-if="provider.bills.length === 0">
              <th>
                Orden
              </th>
              <th>
                Servicio
              </th>
              <th>
                Fecha
              </th>
              <th>
                CANT
              </th>
              <th>
                Total
              </th>
            </tr>
            <tr v-for="(invoice, indexIn) in provider.invoices" v-bind:key="indexIn">
              <td style="width: 10% !important; text-align: center !important;">{{ invoice.id_order }}</td>
              <td style="width: 50% !important;">Comision Servicio ORDEN {{ invoice.id_order }}</td>
              <td style="width: 15% !important;"></td>
              <td style="width: 10% !important; text-align: center !important;">1</td>
              <td style="width: 25% !important; text-align: right !important;">$ {{ invoice.invoice.ecommerce_amount }}
              </td>
            </tr>
          </table> -->
          </div>
          <div style="height: 1% !important;"></div>
          <!-- <table class="orderTableStyle" v-for="(provider, indexInv) in [pseReport[0]]" v-bind:key="indexInv">
          <tr>
            <th colspan="5">
              ORDENES LAVARCLICK
            </th>
          </tr>
          <tr>
            <td colspan="5">

            </td>
          </tr>
          <tr>
            <th style="width: 10%;">
              Orden
            </th>
            <th>
              Servicio
            </th>
            <th>
              Fecha
            </th>
            <th>
              Vr Uni
            </th>
            <th>
              Total
            </th>
          </tr>
          <tr v-for="(bill, indexBill) in provider.bills" v-bind:key="indexBill">
            <td colspan="5">
              <table style="width: 100%;">
                <tr v-for="(invoice, indexPO) in bill.bill.accounting.invoices" v-bind:key="indexPO">
                  <td>{{ bill.fk_ecommerce_order_ec_billing }}</td>
                  <td>{{ bill.name }}</td>
                  <td @click="describeInvoice(invoice)">{{
                    invoice.ecommerce_amount + invoice.affiliate_amount
                  }}</td>
                </tr>
              </table>

            </td>

          </tr>
        </table> -->
      </b-card>
        </b-col>






        <b-col cols="12" xl="4">
          <b-img :src="img" id="screenshot" style="width: 100%;" />
          <b-card no-body class="invoice-preview-card" id="bill">


            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row flex-column mt-0">

                <!-- Header: Left Content -->
                <div style="text-align: center;">
                  <div class="logo-wrapper">
                    <b-img fluid :src="require('@/assets/images/logo/logo2.png')" class="pricing-trial-img" alt="svg img"
                      style="width: 40%" />
                    <h4 class="text-primary invoice-logo" style="font-size: 1.8em;">
                      Estado Cuenta
                    </h4>
                  </div>
                </div>

                <!-- Header: Right Content -->
                <div class="row" style="padding-top: 5%;">
                  <div style="padding-left: 20% !important; width: 350px !important;">
                    <p class="card-text mb-25">
                      Lavar Click S.a.S
                    </p>
                    <p class="card-text mb-25">
                      NIT 900511050-1
                    </p>
                    <p class="card-text mb-25">
                      Calle 95 # 13 - 55 Oficina 304<br />
                      Bogota D.C - Colombia
                    </p>
                    <p class="card-text mb-0">
                      350 4888077
                    </p>
                  </div>

                </div>
                <div class="" style="padding-top: 5%;">
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" block @click="createPayOrder">
                    Enviar_Orden
                  </b-button>
                </div>
              </div>
            </b-card-body>
            <hr />
            <b-card-body v-if="invoiceData.client" class="invoice-padding pt-0">
              <b-row class="invoice-spacing">

                <!-- Col: Invoice To -->
                <b-col cols="12" xl="5" class="p-0">
                  <h6 class="mb-2">
                    CLIENTE
                  </h6>
                  <h6 class="mb-25" v-if="computedBillDescription.items.length > 0">
                    {{ computedBillDescription.partner.name }}
                  </h6>
                  <p class="card-text mb-25">
                    NIT 800.000.000-5
                  </p>
                  <p class="card-text mb-25" v-if="computedBillDescription.partner.hasOwnProperty('plan')">
                    {{ computedBillDescription.partner.plan.planning.postal_address.substring(0, 100) }}
                  </p>
                  <p class="card-text mb-25">
                    Bogota D.C, Colombia
                  </p>

                </b-col>
                <b-col cols="12" xl="1">
                </b-col>
                <b-col xl="5">
                  <div>
                    <h6 class="mb-2">
                      RESUMEN ORDEN PAGO
                    </h6>
                    <table v-if="computedBillDescription.hasOwnProperty('total_invoice')">
                      <tbody>
                        <tr>
                          <td class="pr-1">
                            Total Pagos
                          </td>
                          <td><span class="font-weight-bold">{{ computedBillDescription.total_payorder }}</span></td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            Total Comisiones
                          </td>
                          <td>
                            <span class="font-weight-bold">{{ computedBillDescription.total_invoice }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            Pais :
                          </td>
                          <td>{{ computedBillDescription.customer.location.country_name }}</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            Ciudad :
                          </td>
                          <td>{{ computedBillDescription.customer.location.city_name }}</td>
                        </tr>
                        <!-- <tr>
                        <td class="pr-1">
                          IBAN:
                        </td>
                        <td>{{ paymentDetails.iban }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          SWIFT code:
                        </td>
                        <td>{{ paymentDetails.swiftCode }}</td>
                      </tr> -->
                      </tbody>
                    </table>
                  </div>
                </b-col>

                <b-col xl="12">
                  <!-- {{ computedBillDescription.items }} -->
                  <b-table-lite style="padding-top: 4%;" responsive :items="computedBillDescription.items" :fields="[{
                    key: 'name',
                    label: 'Servicio',
                  }, {
                    key: 'items',
                    label: 'Items',
                  }, {
                    key: 'customer',
                    label: 'Cliente',
                  }, {
                    key: 'payOrders',
                    label: 'Valor',
                  }]">
                    <template #cell(name)="data">
                      <b-card-text class="font-weight-bold mb-25">
                        {{ data.item.name }}
                      </b-card-text>
                      <b-card-text class="text-nowrap">
                      </b-card-text>
                    </template>
                    <template #cell(items)="data">
                      <b-card-text class="font-weight-bold mb-25" v-for="(item, indIt) in data.item.items"
                        v-bind:key="indIt">
                        {{ item.name }}
                      </b-card-text>
                      <b-card-text class="text-nowrap">
                      </b-card-text>
                    </template>
                    <template #cell(customer)="data">
                      <b-card-text class="font-weight-bold mb-25">
                      </b-card-text>
                      <b-card-text class="text-nowrap">
                        {{ computedBillDescription.customer.names + ' ' + computedBillDescription.customer.surnames }}

                      </b-card-text>
                    </template>
                    <template #cell(payOrders)="data">

                      <!-- {{ computedBillDescription.payOrders[data.index] }} -->
                      <b-card-text class="font-weight-bold mb-25"
                        v-for="(item, indIt) in computedBillDescription.payOrders[data.index]" v-bind:key="indIt">
                        <b-card-text class="text-nowrap" v-for="(pay, indP) in item" v-bind:key="indP">
                          <span v-if="indP === 0">{{ pay.amount }}</span>
                        </b-card-text>
                      </b-card-text>

                    </template>
                  </b-table-lite>
                  <b-table-lite style="padding-top: 3%;" responsive :items="computedBillDescription.invoices" :fields="[{
                    key: 'id_order',
                    label: 'ID ORDEN',
                  }, {
                    key: 'type',
                    label: 'Concepto',
                  }, {
                    key: 'invoice',
                    label: 'Valor',
                  }]">
                    <template #cell(invoice)="data">
                      <b-card-text class="font-weight-bold mb-25">
                        {{ data.item.invoice.ecommerce_amount }}
                      </b-card-text>
                      <b-card-text class="text-nowrap">
                      </b-card-text>
                    </template>
                    <template #cell(type)="data">
                      <b-card-text class="font-weight-bold mb-25">
                        {{ data.item.type }}
                      </b-card-text>
                      <b-card-text class="text-nowrap">
                      </b-card-text>
                    </template>
                    <template>
                      <b-card-text class="font-weight-bold mb-25">
                        {{ 'hola' }}
                      </b-card-text>
                      <b-card-text class="text-nowrap">
                      </b-card-text>
                    </template>
                    <template #cell(id_order)="data">
                      <b-card-text class="font-weight-bold mb-25">
                        {{ data.item.id_order }}
                      </b-card-text>
                      <b-card-text class="text-nowrap">
                      </b-card-text>
                    </template>
                  </b-table-lite>

                </b-col>
              </b-row>
            </b-card-body>
            <!-- Invoice Description: Table -->

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>

                <!-- Col: Sales Persion -->
                <b-col cols="12" md="6" class="" order="2" order-md="1" style="padding-left: 5% !important;">
                  <b-card-text class="mb-0">
                    <span class="font-weight-bold">Cliente Final: </span>
                    <span class="ml-75">{{ computedBillDescription.customer.full_name }} {{
                      computedBillDescription.customer.email }}<br />{{ computedBillDescription.customer.type }}</span>
                  </b-card-text>
                </b-col>

                <!-- Col: Total -->
                <b-col cols="12" md="6" class="mt-md-6 d-flex justify-content-end" order="1" order-md="2">
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total Facturas
                      </p>
                      <p class="invoice-total-amount">
                        $ {{ computedBillDescription.total_invoice }}
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total Pagos
                      </p>
                      <p class="invoice-total-amount">
                        $ {{ computedBillDescription.total_payorder }}
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Tax:
                      </p>
                      <p class="invoice-total-amount">
                        21%
                      </p>
                    </div>
                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total:
                      </p>
                      <p class="invoice-total-amount">
                        $ {{ computedBillDescription.status }}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">Note: </span>
              <span>It was a pleasure working with you and your team. We hope you will keep us in mind for future
                freelance
                projects. Thank You!</span>
            </b-card-body>
          </b-card>
        </b-col>

        <!-- Right Col: Card -->

      </b-row>

      <invoice-sidebar-send-invoice @cancelNewCycle="cancelNewCycle" @createNewCycle="createNewCycle" />
      <invoice-sidebar-add-payment />
    </section>
  </b-overlay>
</template>

<script>
import { ref, onUnmounted, computed } from '@vue/composition-api';
import ToastContent from '@/lavarclick/components/utils/ToastContent.vue';
import store from '@/store'
import router from '@/router'
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BImg, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BOverlay
} from 'bootstrap-vue';
import moment from 'moment';
import TimmingManager from '@/lavarclick/ecommerce/TimmingManager.ts';
import flatPickr from 'vue-flatpickr-component'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import invoiceStoreModule from './invoiceStoreModule'
import InvoiceSidebarSendInvoice from '@/lavarclick/components/billing/invoice/InvoiceSidebarSendInvoice.vue'
import InvoiceSidebarAddPayment from '@/lavarclick/components/billing/invoice/InvoiceSidebarAddPayment.vue'
import ECommerce from '@/lavarclick/ecommerce/ECommerce.ts';
import html2canvas from 'html2canvas';
window.html2canvas = html2canvas;

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup,
    BCol,
    BCard,
    BCardBody,
    BTableLite, BOverlay,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BImg,
    Logo,
    InvoiceSidebarAddPayment,
    InvoiceSidebarSendInvoice,
    flatPickr, ToastContent
  },
  methods: {

    async updateCycleStatus(cycle, status) {

      this.eCommerce.updateCycleStatus({
        id_cycle: cycle.id_ecommerce_cycles,
        status: status
      }).then((result) => {
        let message = "";
        if(status === 0) {
          message = `Se Ha Aprobado Con Exito El Ciclo `;
        }else {
          message = `Se Ha Terminado Con Exito El Ciclo `;
        }
        this.$toast({
          component: ToastContent,
          position: 'b-toaster-top-center',
          props: {
            title: message,
            icon: 'CoffeeIcon',
            variant: 'default',
            text: `Muy Bien !`,
            bgcolor: '#55a64b'

          },
          delay: 15000
        });
        this.flagReport = false;
        this.eCommerce.getECommerceCycles({
        type: 0
      }).then((cyclesList) => {
        this.computedCycleReportList = cyclesList;
        this.flagReport = true;
        console.log('cycles 897897 ', cyclesList);
      })

      
      })
    },

    async getCycleDispersion(cycle, indexCycle) {

      console.log('bruce ', cycle);
      this.eCommerce.getECommerceCycleDispersion({
        id_ecommerce_cycle: cycle.id_ecommerce_cycles,
        created_at: cycle.created_at
      }).then((cycleDispersion) => {
        this.dispersionReport = cycleDispersion;

        console.log('vamo bien ', cycleDispersion);
        Object.keys(cycleDispersion[0]).forEach((dispersion) => {
          this.dispersionColumnsReport.push({
            label: dispersion,
            field: dispersion
          })
        });

      })

      this.eCommerce.getECommerceCycleInvoices({
        id_ecommerce_cycle: cycle.id_ecommerce_cycles,
        created_at: cycle.created_at
      }).then((cycleDispersion) => {
        console.log('facturas disper ', cycleDispersion);
        this.invoicesReport = cycleDispersion;

        console.log('vamo bien 2', cycleDispersion);
        Object.keys(cycleDispersion[0]).forEach((dispersion) => {
          this.invoicesColumnsReport.push({
            label: dispersion,
            field: dispersion
          })
        });

      })

      this.createOldCycleSheet(indexCycle);
      
    },

    async createNewCycle(newCycleResume) {

      this.eCommerce.createNewCycle({
        id_owner: newCycleResume.owner.id,
        cycle: this.computedCycleReport,
        observation: newCycleResume.cycleResume.message
      }).then((result) => {
        this.$toast({
          component: ToastContent,
          position: 'b-toaster-top-center',
          props: {
            title: `Se Ha Creado Con Exito El Nuevo Ciclo `,
            icon: 'CoffeeIcon',
            variant: 'default',
            text: `Muy Bien !`,
            bgcolor: '#55a64b'

          },
          delay: 15000
        });
        console.log('result ', result);
      })

    },

    async createPayOrder() {

      this.eCommerce.createPayOrder({
        id_provider: this.computedBillDescription.id_provider
      }).then((result) => {

        console.log('payord ', result);

      });

    },


    exportExcel() {

    },

    takeScreenshot() {
      console.log('entran')
      html2canvas(document.getElementById('bill')).then(canvas => {
        let croppedCanvas = document.createElement('canvas'),
          croppedCanvasContext = croppedCanvas.getContext('2d');

        croppedCanvas.width = 500;
        croppedCanvas.height = 1000;
        croppedCanvas.setAttribute('style', 'background-color: #FFFFFF;')

        croppedCanvasContext.drawImage(canvas, 0, 0, 500, 1000);

        this.imageUrl = croppedCanvas.toDataURL();
        this.img = this.imageUrl;

        setTimeout(() => {
          this.img = '';
        }, 5000);

      });


    },

    async describeInvoice(provider) {

      let buff = [];
      const payOrders = [];
      let total_payorder = ref(0);
      let total_invoice = ref(0);
      console.log('pum ', provider);
      provider.invoices.forEach((invoice) => {
        buff = [];
        console.log('rayito ', invoice.invoice, provider)
        this.computedBillDescription = {
          id_provider: provider.id_provider,
          partner: {
            name: provider.name
          },
          items: [],
          customer: {
            names: 'ceSa',
            surnames: '',
            location: {
              country_name: 'Colombia'
            }
          },
          invoices: provider.invoices,
          payOrders: [],
          total_invoice: total_invoice,
          total_payorder: total_payorder

        }
        total_invoice += invoice.invoice.ecommerce_amount;

      })
      provider.bills.forEach((bill) => {

        bill.bill.accounting.payOrders.forEach((payOrder) => {
          if (payOrder.id_provider === provider.id_provider) {
            total_payorder += payOrder.amount;
          }
        })

        payOrders.push({
          payOrders: bill.bill.accounting.payOrders
        });
        bill.bill.items.forEach((items) => {

          items.partners.forEach((item) => {
            if (item.id_provider === provider.id_provider) {
              buff.push(items);
              this.computedBillDescription = {
                id_provider: provider.id_provider,
                partner: item,
                items: buff,
                customer: bill.bill.customer,
                invoices: provider.invoices,
                payOrders: payOrders,
                total_invoice: total_invoice,
                total_payorder: total_payorder

              }
            }
          })

        })

      })

      console.log('competencia ', total_payorder, total_invoice, this.computedBillDescription);




    },

    async cancelNewCycle(eventx) {

      this.computedCycleReport = [];

    },

    async createCycleTestSheet() {

      this.testReportExport = [];
      this.computedCycleReport.forEach((report, index) => {

        report.cycle[index].invoices.forEach((invoice) => {
          if (report.id_provider === invoice.invoice.id_provider) {
            this.testReportExport.push({
              id_provider: invoice.invoice.id_provider,
              type: 'COMISION',
              ecommerce_amount: invoice.invoice.ecommerce_amount,
              name: report.partner_name,
              query_date: report.cycle[index].partner.plan.planning.query_date,
              id_order: invoice.id_order
            })

          }

        });

        report.cycle[index].payOrders.forEach((payOrders) => {

          console.log('shut ', report)
          payOrders.payOrders.forEach((payOrder) => {
            console.log('famoos', payOrder.id_provider, report.id_provider);
            if (payOrder.id_provider === report.id_provider) {
              this.testReportExport.push({
                id_provider: payOrder.id_provider,
                type: 'PAGO',
                ecommerce_amount: payOrder.amount,
                name: report.partner_name,
                query_date: report.cycle[index].partner.plan.planning.query_date,
                id_order: 0
              })

            }
          })

        })

      });
    

      console.log('cuba ', this.testReportExport);


    },

    async createOldCycleSheet(indexList) {

this.testReportExport = [];
console.log('divino ', this.computedCycleReportList);
this.computedCycleReportList[indexList].cycle.forEach((report, index) => {

  if(index < report.cycle.length ) {
    this.total_pay += report.total_payorder;
    this.total_inv += report.total_invoice;
    console.log('muy ', report.total_invoice, report.total_payorder);
  report.cycle[index].invoices.forEach((invoice) => {
    if (report.id_provider === invoice.invoice.id_provider) {
      this.testReportExport.push({
        id_provider: invoice.invoice.id_provider,
        type: 'COMISION',
        ecommerce_amount: invoice.invoice.ecommerce_amount,
        name: report.partner_name,
        query_date: report.cycle[index].partner.plan.planning.query_date,
        id_order: invoice.id_order
      })

    }

  });

  report.cycle[index].payOrders.forEach((payOrders) => {

    console.log('shut ', report)
    payOrders.payOrders.forEach((payOrder) => {
      console.log('famoos', payOrder.id_provider, report.id_provider);
      if (payOrder.id_provider === report.id_provider) {
        this.testReportExport.push({
          id_provider: payOrder.id_provider,
          type: 'PAGO',
          ecommerce_amount: payOrder.amount,
          name: report.partner_name,
          query_date: report.cycle[index].partner.plan.planning.query_date,
          id_order: 0
        })

      }
    })

  })
  }
});


console.log('cuba ', this.testReportExport);


},    

    async runCycle() {
      const cycle = [];
      this.total_payorders = 0;
      this.total_invoices = 0;
      this.report.forEach((provider, index) => {

        const buff = [];
        const payOrders = [];
        let total_payorder = 0;
        let total_invoice = 0;
        provider.invoices.forEach((invoice) => {

          total_invoice += invoice.invoice.ecommerce_amount;

        })
        provider.bills.forEach((bill) => {

          bill.bill.accounting.payOrders.forEach((payOrder) => {
            if (payOrder.id_provider === provider.id_provider) {
              total_payorder += payOrder.amount;
            }
          })



          payOrders.push({
            payOrders: bill.bill.accounting.payOrders
          });
          bill.bill.items.forEach((items) => {

            items.partners.forEach((item) => {
              if (item.id_provider === provider.id_provider) {
                buff.push(items);
                this.computedBillDescription = {
                  id_provider: provider.id_provider,
                  partner: item,
                  items: buff,
                  customer: bill.bill.customer,
                  invoices: provider.invoices,
                  payOrders: payOrders,
                  total_invoice: total_invoice,
                  total_payorder: total_payorder

                }
                cycle[index] = this.computedBillDescription;
              }
            })

          })

          this.total_payorders += total_payorder;
          this.total_invoices += total_invoice;
          console.log('competenciasss ', total_payorder, total_invoice, cycle);

        })
        this.computedCycleReport.push({
          status: (total_payorder - total_invoice),
          total_payorder: total_payorder,
          total_invoice: total_invoice,
          cycle: cycle,
          partner_name: provider.name,
          id_provider: provider.id_provider

        });


      })
      console.log('reporte listo ', this.computedCycleReport);
      this.createCycleTestSheet();

    }


  },
  setup() {

    const momentInvoice = require('moment');
    let total_pay = 0;
    let total_inv = 0;

    let loading = ref(true);
    let testReportExport = ref([]);
    let total_payorders = ref(0);
    let total_invoices = ref(0);

    let search_query = ref('');
    let cycleReport = ref([]);
    const computedCycleReport = computed({
      get: () => { return cycleReport.value },
      set: (newReport) => {
        cycleReport.value = newReport;
      }
    })

    let cycleReportList = ref([]);
    const computedCycleReportList = computed({
      get: () => { return cycleReportList.value },
      set: (newReport) => {
        cycleReportList.value = newReport;
      }
    })

    let rangePicker = ref([moment(new Date()).format('YYYY-MM-DD'), new TimmingManager().getPastDateFromNow(15)]);
    let columns = ref([
      {
        label: "Tercero",
        field: "partner_name",
      },
      {
        label: "Total Pago",
        field: "total_payorder",
      },
      {
        label: "Total Comision",
        field: "total_invoice",
      },
      {
        label: "ESTADO",
        field: "status",
      }
    ]);
    let dispersionColumnsReport = ref([]);
    let dispersionReport = ref([]);
    let invoicesColumnsReport = ref([]);
    let invoicesReport = ref([]);

    let columnsTestReport = ref([
      {
        label: "PROVEEDOR",
        field: "id_provider",
      },
      {
        label: "VALOR",
        field: "ecommerce_amount",
      },
      {
        label: "TIPO",
        field: "type",
      },
      {
        label: "AFILIADO",
        field: "name",
      },
      {
        label: "FECHA",
        field: "query_date",
      },
      {
        label: "ORDER",
        field: "id_order",
      }
    ]);
    let data = ref([
      {
        product: "Beverage",
        price: 10,
        quantity: 2,
        address: {
          country: 'Armenia',
          city: 'Yerevan',
        }
      },
      {
        product: "Snack",
        price: 12,
        quantity: 6,
        address: {
          country: 'Armenia',
          city: 'Yerevan',
        }
      },
      {
        product: "Beverage",
        price: 10,
        quantity: 5,
        address: {
          country: 'Armenia',
          city: 'Yerevan',
        }
      },
      {
        product: "Snack",
        price: 12,
        quantity: 3,
        address: {
          country: 'Armenia',
          city: 'Yerevan',
        }
      }
    ]);
    let img = ref('');
    const billDescription = ref({
      partner: {
        name
      },
      items: [],
      customer: {}
    })
    const computedBillDescription = computed({
      get: () => { return billDescription.value },
      set: (newDes) => {
        billDescription.value = newDes;
      }
    })
    const invoiceData = ref(null)
    const paymentDetails = ref({})
    const eCommerce = new ECommerce();
    let report = ref([]);
    let reportInvoice = ref([]);
    let pseReport = ref([]);
    let billingList = ref([]);
    const computedBillingList = computed({
      get: () => { return billingList.value },
      set: (newList) => {

        let qty = 0;
        let total_price = 0;
        billingList.value = [];

        newList.forEach((bill) => {

          let buff = [];

          bill.bills.forEach((billP) => {
            console.log('puch ', billP);

            billP.bill.items.forEach((item) => {
              item.partners.forEach((partner) => {
                if (partner.id_provider === bill.id_provider) {
                  buff.push({
                    name: item.name,
                    id_provider: bill.id_provider,
                    bill: bill.bill,
                    id_bill: bill.id_ecommerce_billing
                  });
                }


              })

            });

          });
          if (buff.length > 0) {
            billingList.value.push(buff);
          }else {
            billingList.value.push([{
                    name: '',
                    id_provider: bill.id_provider,
                    bill: {},
                    id_bill: bill.id_ecommerce_billing
                  }]);
            
          }

        })
      }
    })

    // Invoice Description
    // ? Your real data will contain this information
    const invoiceDescription = [
      {
        taskTitle: 'Native App Development',
        taskDescription: 'Developed a full stack native app using React Native, Bootstrap & Python',
        rate: '$60.00',
        hours: '30',
        total: '$1,800.00',
      },
      {
        taskTitle: 'UI Kit Design',
        taskDescription: 'Designed a UI kit for native app using Sketch, Figma & Adobe XD',
        rate: '$60.00',
        hours: '20',
        total: '$1200.00',
      },
    ]

    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      flagReport.value = false;
      computedBillingList.value = [];
      computedBillDescription.value = {};
      pseReport.value = [];
      report.value = [];
      reportInvoice.value = [];

      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)

    })

    store.dispatch('app-invoice/fetchInvoice', { id: 4987 })
      .then(response => {
        invoiceData.value = response.data.invoice
        paymentDetails.value = response.data.paymentDetails
      })
      .catch(error => {
        if (error.response.status === 404) {
          invoiceData.value = undefined
        }
      })

    const printInvoice = () => {
      window.print()
    }

    store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);
    let flagReport = ref(false);
    return {
      search_query, loading, momentInvoice,
      invoiceData,
      paymentDetails,
      invoiceDescription,
      printInvoice,
      eCommerce, report, computedBillDescription, pseReport, html2canvas, flagReport, img, columns, data, computedBillingList, reportInvoice, rangePicker, computedCycleReport, computedCycleReportList, total_payorders, total_invoices,
      columnsTestReport, testReportExport, total_pay, total_inv, dispersionColumnsReport, dispersionReport, invoicesColumnsReport, invoicesReport
    }
  },
  mounted() {

    this.eCommerce.getECommerceReport({
      id_order: 0,
      id_provider: 0,
      billingType: 1,
    }).then(async (report) => {

      this.loading = false;
      this.computedBillingList = report;
      this.report = report;

      this.report.forEach((provi) => {

        provi.invoices = [];
        const buff = [];

        provi.bills.forEach((bill) => {

          if (bill.bill.accounting.payOrders.length === 0) {
            bill.bill.accounting.invoices.forEach((invoice) => {

              if (invoice.id_provider === provi.id_provider) {
                console.log('sicacacac');
                provi.invoices.push({
                  invoice: invoice,
                  id_order: bill.fk_ecommerce_order_ec_billing,
                  type: "COMISION SERVICIO ID " + bill.fk_ecommerce_order_ec_billing
                })
              }

            })

            // console.log('korea ', provi.invoices.push())
          } else {

            bill.bill.accounting.payOrders.forEach((payOrd, indexx) => {

              if(payOrd.id_provider === provi.id_provider) {
                console.log('pañal ', payOrd);
              }
            });

          }

        })

      });

      console.log('korea ', this.report)

      this.eCommerce.getECommerceCycles({
        type: 0
      }).then((cyclesList) => {
        this.computedCycleReportList = cyclesList;
        console.log('cycles 897897 ', cyclesList);
      })

      this.flagReport = true;
    })
    // this.eCommerce.getCategories();
    // this.eCommerce.getECommerceReport({
    //   id_order: 0,
    //   id_provider: 0,
    //   billingType: 0,
    // }).then((report) => {
    //   this.pseReport = report;

    //   console.log('si se puede ', report);
    //   this.eCommerce.getECommerceReport({
    //     id_order: 0,
    //     id_provider: 0,
    //     billingType: 1,
    //   }).then((report) => {
    //           this.loading = false;
    //     this.computedBillingList = report;
    //     this.report = report;
    //     console.log('si se puede ', report);
    //     this.eCommerce.getECommerceReport({
    //       id_order: 0,
    //       id_provider: 0,
    //       billingType: 2,
    //     }).then(async (report) => {

    //       await new Promise((resolve, reject) => {

    //         this.report.forEach((invoices) => {
    //           invoices.invoices = [];
    //           report.forEach((invoice, index) => {

    //             if (invoice.id_provider === invoices.id_provider) {

    //               invoice.bills.forEach((bill) => {

    //                 bill.bill.accounting.invoices.forEach((invoice2) => {

    //                   if (invoice2.id_provider === invoice.id_provider) {
    //                     invoices.invoices.push({
    //                       invoice: invoice2,
    //                       id_order: bill.fk_ecommerce_order_ec_billing,
    //                       type: "COMISION SERVICIO ID " + bill.fk_ecommerce_order_ec_billing
    //                     })
    //                   }

    //                 })

    //               })

    //             } else {
    //               if (index === (report.length - 1)) {
    //                 this.loading = false;
    //                 resolve(true);
    //               }

    //             }

    //           })
    //         })

    //       })

    //       this.flagReport = true;
    //       console.log(this.report);
    //     });

    //   })




    // })



    // this.eCommerce.getECommercePayOrdersReport({
    //   id_provider: 2,
    //   id_pay_orders: 1
    // }).then((result) => {

    //   console.log('activas ', result);
    // })



  }
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.startTrainingHeaderStyle {
  height: 120px !important;
  font-size: 20px !important;
  text-align: center !important;
  margin-top: 0px !important;
  width: 100% !important;
  font-family: MavenPro !important;
}

@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            >.col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}

.orderTableStyle {
  margin-left: 2% !important;
  font-family: MavenPro !important;
  width: 95% !important;
  margin-top: 0px !important;

}

.orderTableStyle th {
  border-style: solid;
  border-width: 0.1em;
  border-color: #6d36ac;
  font-size: 1.1em !important;
  color: #6d36ac !important;
  background-color: #FFFFFF !important;
  text-align: center !important;
  font-family: MavenPro !important;
}

.orderTableStyle td {
  border: #4e95f4 0px solid;
  text-align: justify !important;
  font-family: MavenPro !important;

}

/* provide some minimal visual accomodation for IE8 and below */
.orderTableStyle tr {
  background: #b8d1f3;
}

/*  Define the background color for all the ODD background rows  */
.orderTableStyle tr:nth-child(odd) {
  background: #f2edf7;
}

/*  Define the background color for all the EVEN background rows  */
.orderTableStyle tr:nth-child(even) {
  background: #f2faf0;
}</style>
